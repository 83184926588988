import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import ImpItem from "./components/ImpItem";
import ImpModal from "./components/ImpModal";
import ExpItem from "./components/ExpItem";
import ExpModal from "./components/ExpModal";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

import "../StyleSheet.css";
import ExpItemMG from "./components/ExpItemMG";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const MAIN_SELECT_PATH = {
  IMP: "/app/JS_imp_main_list_min.php",
  EXP: "/app/JS_exp_main_list_min_mg.php",
};

const today = new Date();
const firstDay = util.getDateToFormat(
  new Date(today.getFullYear(), today.getMonth(), 1),
  "YYYY-MM-DD"
);
const todayFormat = util.getDateToFormat(today, "YYYYMMDD");
const todayFormatDisplay = util.getDateToFormat(today, "YYYY-MM-DD");

const useRowStyles = makeStyles({
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
    "& li > button": {
      margin: "0px !important",
    },
  },
});

let loginInfo = null;

let isTouchBlock = true;
let impExpBack = "IMP"; //"IMP";
let rowsPerPage = 20;
let totalPageCount = 1;
let totalCount = 0;
let page = 0;
let isReceiving = false;
let filter = {
  searchStartDate: todayFormat,
  searchEndDate: todayFormat,
};

export default function AppWebViewLogiWork() {
  const [isMG, setIsMG] = useState(false);
  const [loading, setLoading] = useState(true); //로딩중 여부
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [isShowFilters, setIsShowFilters] = useState(true);
  const [serverData, setServerData] = useState([]);
  const [langMode, setLangMode] = useState("strait");
  const [impExp, setImpExp] = useState("IMP");

  const { t, i18n } = useTranslation();
  const classes = useRowStyles();

  const impModal = useRef();
  const expModal = useRef();
  const searchController = {
    searchStartDate: useRef(),
    searchShowStartDate: useRef(),
    searchEndDate: useRef(),
    searchShowEndDate: useRef(),
    searchDateType: useRef(),
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };
  //
  // (() => {
  //   if (window.ReactNativeWebView === undefined) {
  //     alert("잘못된 접근입니다.");
  //     window.location.replace("/");
  //   }
  // })();

  useEffect(() => {
    document.addEventListener("message", receiveMessage);

    window.addEventListener("message", receiveMessage);

    //테스트용 로그인정보 todo
    let loginData = util.buddibleDecrypt(localStorage.getItem("loginInfo"));
    loginData = JSON.parse(loginData);
    loginData.key = "init";
    // loginData.lang = "en";
    loginData = JSON.stringify(loginData);
    loginData = util.buddibleEncrypt(loginData);
    receiveMessage({ data: loginData });

    return () => {
      window.removeEventListener("message", receiveMessage);
      document.removeEventListener("message", receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (loginInfo) {
      requestLogiworkDataSelect(() => {
        scrollToTop();
      });
    }
  }, [impExp]);

  const scrollToTop = () => {
    const scrollWrap = document.querySelector("#scrollWrap");
    if (scrollWrap) {
      // setTimeout(() => {
      scrollWrap.scrollTo({ top: 0, behavior: "smooth" });
      // }, 100);
    }
  };

  //

  const receiveMessage = (event) => {
    let data = util.buddibleDecrypt(event.data);

    if (!data || isReceiving) {
      return;
    }

    isReceiving = true;
    data = JSON.parse(data);

    if (data.key === "init") {
      i18n.changeLanguage(data.lang, (err) => console.log(err));
      if (data.lang === "en") {
        setLangMode("wide");
      }

      const loginData = {
        comp_cd: data.comp_cd,
        user_id: data.user_id,
        user_pw: data.user_pw,
      };
      requestLogin(loginData, () => {
        checkMGUser(loginData, () => {
          if (loginInfo["isMG"] && searchController.searchDateType.current) {
            searchController.searchDateType.current.value = "C";
            changedPeriod();
          } else {
            requestLogiworkDataSelect(() => {});
          }
          isReceiving = false;
          setLoading(false);
        });
      });
    }
  };

  const checkMGUser = (data, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/app/JS_check_mg_user.php",
      msgID: msgID,
      data: {},
      comp_cd: loginInfo.comp_cd,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setIsMG(newData["isMG"]);
          loginInfo.isMG = newData["isMG"];
          callback && callback();
        }
      }
    });
  };

  const requestLogin = (updateData, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          loginInfo = newData["returnData"][0];
          callback && callback();
        }
      }
    });
  };

  const requestLogiworkDataSelect = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: MAIN_SELECT_PATH[impExpBack],
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      isMG: loginInfo ? loginInfo.isMG : "",
      comp_cd: loginInfo ? loginInfo.comp_cd : "",
      comp_gb: loginInfo ? loginInfo.comp_gb : "",
      comp_num: loginInfo ? loginInfo.comp_num : "",
      fwd_no: !loginInfo?.FWD_NO ? "" : loginInfo.FWD_NO,
      ...filter,
    };

    setIsDataLoad(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);

          setServerData([...newData["returnData"]]);

          // setServerData((prevState) => {
          //   if (prevState.length === 0) {
          //     return [...newData["returnData"]];
          //   } else {
          //     return prevState.concat(newData["returnData"]);
          //   }
          // });
          callback && callback();
        } else {
          totalCount = 0;
          totalPageCount = 0;
          setServerData([]);
        }
        isTouchBlock = false;
        setIsDataLoad(false);
        setLoading(false);
      }
    });
  };

  const handleListScroll = () => {
    const scrollWrap = document.querySelector("#scrollWrap");

    if (!scrollWrap) return;

    // if (
    //   !isTouchBlock &&
    //   page < totalPageCount &&
    //   0 < totalCount &&
    //   scrollWrap.scrollHeight - scrollWrap.clientHeight - 1000 <
    //     Math.round(scrollWrap.scrollTop)
    // ) {
    //   isTouchBlock = true;
    //   setIsDataLoad(true);
    //   setTimeout(() => {
    //     ++page;
    //     requestLogiworkDataSelect();
    //   }, 100);
    // }

    setIsShowFilters((prevState) => {
      if (prevState && scrollWrap.scrollTop > 300) {
        return false;
      }
      if (scrollWrap.scrollTop < 300) {
        return true;
      }
    });
  };
  const changedPeriod = () => {
    const dateType = searchController.searchDateType.current?.value;
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    if (dateType === "A") {
      //   오늘
      startDate.setDate(startDate.getDate());
    }
    if (dateType === "B") {
      //   이번주
      startDate.setDate(startDate.getDate() - theDayOfWeek);
    }
    if (dateType === "C") {
      //   이번달
      startDate.setDate(1);
    }

    searchController.searchStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    searchController.searchEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );

    actionSearch({
      searchShowStartDate: util.getDateToFormat(startDate, "YYYY-MM-DD"),
      searchStartDate: util.getDateToFormat(startDate, "YYYYMMDD"),
      searchShowEndDate: util.getDateToFormat(endDate, "YYYY-MM-DD"),
      searchEndDate: util.getDateToFormat(endDate, "YYYYMMDD"),
    });
  };
  const movePeriod = (calc) => {
    const searchDateType = searchController.searchDateType.current.value;
    let sDate = new Date(searchController.searchStartDate.current.value);
    let eDate = new Date(searchController.searchEndDate.current.value);

    if (calc === "+") {
      switch (searchDateType) {
        case "A":
          sDate.setDate(sDate.getDate() + 1);
          eDate.setDate(eDate.getDate() + 1);
          break;
        case "B":
          sDate.setDate(sDate.getDate() + 7 - sDate.getDay());
          eDate = new Date(
            sDate.getFullYear(),
            sDate.getMonth(),
            sDate.getDate() + 6
          );
          break;
        case "C":
          sDate.setMonth(sDate.getMonth() + 1, 1);
          eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 1, 0);
          break;
        default:
          break;
      }
    } else {
      switch (searchDateType) {
        case "A":
          sDate.setDate(sDate.getDate() - 1);
          eDate.setDate(eDate.getDate() - 1);
          break;
        case "B":
          sDate.setDate(sDate.getDate() - 7 - sDate.getDay());
          eDate = new Date(
            sDate.getFullYear(),
            sDate.getMonth(),
            sDate.getDate() + 6
          );
          break;
        case "C":
          sDate.setMonth(sDate.getMonth() - 1, 1);
          eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 1, 0);
          break;
        default:
          break;
      }
    }

    searchController.searchStartDate.current.value = util.getDateToFormat(
      sDate,
      "YYYY-MM-DD"
    );
    searchController.searchEndDate.current.value = util.getDateToFormat(
      eDate,
      "YYYY-MM-DD"
    );

    actionSearch({
      searchShowStartDate: util.getDateToFormat(sDate, "YYYY-MM-DD"),
      searchStartDate: util.getDateToFormat(sDate, "YYYYMMDD"),
      searchShowEndDate: util.getDateToFormat(eDate, "YYYY-MM-DD"),
      searchEndDate: util.getDateToFormat(eDate, "YYYYMMDD"),
    });
  };

  const selectedDateDetail = () => {
    let sDate = new Date(searchController.searchStartDate.current.value);
    let eDate = new Date(searchController.searchEndDate.current.value);
    actionSearch({
      searchShowStartDate: util.getDateToFormat(sDate, "YYYY-MM-DD"),
      searchStartDate: util.getDateToFormat(sDate, "YYYYMMDD"),
      searchShowEndDate: util.getDateToFormat(eDate, "YYYY-MM-DD"),
      searchEndDate: util.getDateToFormat(eDate, "YYYYMMDD"),
    });
  };

  // 수입수출 변경
  const changedImpExp = (value) => {
    isTouchBlock = true;
    searchController.searchDateType.current.value = loginInfo.isMG ? "C" : "A";
    searchController.searchStartDate.current.value = loginInfo.isMG
      ? firstDay
      : todayFormatDisplay;
    searchController.searchEndDate.current.value = todayFormatDisplay;
    searchController.SEARCH_LEVEL.current.value = "";
    searchController.SEARCH_WORD.current.value = "";
    page = 0;
    totalCount = 0;
    totalPageCount = 1;

    filter = {
      searchStartDate: loginInfo.isMG
        ? firstDay.split("-").join("")
        : todayFormat,
      searchEndDate: todayFormat,
    };

    impExpBack = value;
    setServerData([]);
    setImpExp(value);
  };

  const clearFilters = () => {
    searchController.searchDateType.current.value = "A";
    searchController.searchStartDate.current.value = loginInfo.isMG
      ? firstDay
      : todayFormatDisplay;
    searchController.searchEndDate.current.value = todayFormatDisplay;
    searchController.SEARCH_LEVEL.current.value = "";
    searchController.SEARCH_WORD.current.value = "";
    rowsPerPage = 20;
    totalPageCount = 1;
    totalCount = 0;
    page = 0;
    filter = {
      searchStartDate: loginInfo.isMG
        ? firstDay.split("-").join("")
        : todayFormat,
      searchEndDate: todayFormat,
    };
    setServerData([]);
    requestLogiworkDataSelect();
  };

  const actionSearch = (data) => {
    setServerData([]);
    rowsPerPage = 20;
    totalPageCount = 1;
    totalCount = 0;
    page = 0;
    filter = {
      ...filter,
      ...data,
      SEARCH_LEVEL: searchController.SEARCH_LEVEL.current.value,
      SEARCH_WORD: searchController.SEARCH_WORD.current.value,
    };
    requestLogiworkDataSelect(() => scrollToTop());
  };

  const downloadFiles = (files) => {
    const data = JSON.stringify({
      key: "downloadFiles",
      files: files,
    });
    window.ReactNativeWebView?.postMessage(data);
  };
  const shareFiles = (files) => {
    const data = JSON.stringify({
      key: "shareFiles",
      files: files,
    });
    window.ReactNativeWebView?.postMessage(data);
  };

  const copyInfo = (key, item) => {
    const data = JSON.stringify({
      key: key === "imp" ? "copyImp" : "copyExp",
      ...item,
    });
    window.ReactNativeWebView?.postMessage(data);
  };

  const openImpModal = (e, key, params) => {
    impModal.current?.openModal(e, key, params);
  };

  const openExpModal = (e, key, params) => {
    expModal.current?.openModal(e, key, params);
  };

  const ImpItemRender = useCallback((props) => {
    return <ImpItem {...props} />;
  }, []);

  const ExpItemRender = useCallback(
    (props) => {
      if (isMG) return <ExpItemMG {...props} />;
      return <ExpItem {...props} />;
    },
    [isMG]
  );

  const renderItems = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  return (
    <>
      <ImpModal
        ref={impModal}
        downloadFiles={downloadFiles}
        shareFiles={shareFiles}
      />
      <ExpModal
        ref={expModal}
        downloadFiles={downloadFiles}
        shareFiles={shareFiles}
      />
      <div className="vh-100 vw-100 bg-blue-light d-flex flex-column">
        <div className="bg-white font-weight-bold border-bottom border-width-2">
          <div className="row m-0 pt-2 pb-1 px-3">
            <p
              className={`col-6 m-0 p-2 text-center border-bottom transition-3 ${
                impExp === "IMP"
                  ? "border-width-2 border-gbts text-gbts font-weight-bold"
                  : ""
              }`}
              onClick={() => {
                if (isTouchBlock) return;
                changedImpExp("IMP");
              }}
            >
              {t("impManage")}
            </p>
            <p
              className={`col-6 m-0 p-2 text-center border-bottom transition-3 ${
                impExp === "EXP"
                  ? "border-width-2 border-gbts text-gbts font-weight-bold"
                  : ""
              }`}
              onClick={() => {
                if (isTouchBlock) return;
                changedImpExp("EXP");
              }}
            >
              {t("expManage")}
            </p>
          </div>
          <div
            className={`transition-3 overflow-hidden ${
              isShowFilters ? "max-height-500" : "max-height-0"
            }`}
          >
            <div className="pt-2 px-3">
              <div className="d-flex flex-row rounded bg-light overflow-hidden text-secondary">
                <div
                  className="p-2 border-right border-white border-width-4 bg-secondary text-white bg-dark-light cursor_pointer"
                  onClick={() => {
                    if (isTouchBlock) return;
                    movePeriod("-");
                  }}
                >
                  <i className="fa-solid fa-chevron-left mt-1" />
                </div>

                <select
                  className="flex-fill bg-light font-weight-bold border-0 text-secondary text-center p-2"
                  ref={searchController.searchDateType}
                  onChange={() => changedPeriod()}
                  disabled={isTouchBlock}
                >
                  <option value="A">{t("day")}</option>
                  <option value="B">{t("week")}</option>
                  <option value="C">{t("month")}</option>
                </select>

                <div
                  className=" p-2 border-left border-white border-width-4 bg-secondary text-white bg-dark-light cursor_pointer"
                  onClick={() => {
                    if (isTouchBlock) return;
                    movePeriod("+");
                  }}
                >
                  <i className="fa-solid fa-chevron-right mt-1" />
                </div>
              </div>
            </div>
            <div className="pt-2 px-3">
              <div className="d-flex flex-row rounded bg-light overflow-hidden text-secondary">
                <div className="flex-grow-1 p-2">
                  {/*시작일*/}
                  <input
                    type="date"
                    className="border-0 bg-light w-100 text-center"
                    defaultValue={todayFormatDisplay}
                    ref={searchController.searchStartDate}
                    disabled={isTouchBlock}
                    onChange={() => selectedDateDetail()}
                  />
                </div>
                <div className="p-2 d-flex flex-row align-items-center text-white border-right border-white border-width-2 border-left border-white border-width-2 bg-dark-light">
                  <span>-</span>
                </div>
                <div className="flex-grow-1 p-2">
                  {/*종료일*/}
                  <input
                    type="date"
                    className="border-0 bg-light w-100 text-center"
                    defaultValue={todayFormatDisplay}
                    ref={searchController.searchEndDate}
                    disabled={isTouchBlock}
                    onChange={() => selectedDateDetail()}
                  />
                </div>
              </div>
            </div>
            <div className="pt-2 px-3">
              <div className="d-flex flex-row rounded text-secondary bg-light">
                {/*overflow-hidden */}
                <div className="d-flex flex-row align-items-center">
                  <select
                    className="h-100 min-width-100 p-2 bg-light border-bottom-0 border-top-0 border-left-0 border-right border-white border-width-2"
                    ref={searchController.SEARCH_LEVEL}
                    disabled={isTouchBlock}
                  >
                    <option value="">{t("condition")}</option>
                    {impExp === "IMP" && (
                      <>
                        <option value="RPT_NO">{t("reportNo")}</option>
                        <option value="BLNO">{t("blNo")}</option>
                      </>
                    )}
                    {impExp === "EXP" && (
                      <>
                        <option value="RPT_NO">{t("reportNo")}</option>
                        <option value="IV_NO">{t("invNo")}</option>
                      </>
                    )}
                  </select>
                </div>
                <div className="flex-grow-1 p-2 border-left border-white border-width-2">
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <input
                      className="border-0 bg-transparent w-100 flex-grow-1"
                      placeholder={t("enterSearchTerm")}
                      ref={searchController.SEARCH_WORD}
                      disabled={isTouchBlock}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          actionSearch();
                        }
                      }}
                    />
                    <button
                      className="bg-transparent border-0"
                      disabled={isTouchBlock}
                      onClick={() => {
                        actionSearch();
                      }}
                    >
                      <i className="fa-solid fa-magnifying-glass" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h6 className="m-0 font-weight-bold">
                {t("total")}{" "}
                <span className="text-gbts">
                  {totalCount ? util.addCommas(totalCount) : "-"}
                </span>{" "}
                {t("searchResult")}
              </h6>
              <button
                className="btn btn-sm btn-secondary d-flex flex-row align-items-center"
                disabled={isTouchBlock}
                onClick={() => {
                  clearFilters();
                }}
              >
                {t("resetSearch")}{" "}
                <i className="fa-solid fa-rotate-left ml-2" />
              </button>
            </div>
          </div>
          <div
            className="bg-light text-center p-2 border-top"
            onClick={() => setIsShowFilters((prevState) => !prevState)}
          >
            <i
              className={`transition-8 fa-solid fa-angles-${
                isShowFilters ? "up" : "down"
              }`}
            ></i>
          </div>
        </div>
        <div id="scrollWrap" className="flex-grow-1 p-3 overflow_scroll-Y">
          {renderItems.length === 0 && !isDataLoad && (
            <div className="h-100 font-weight-bold text-secondary bg-white rounded d-flex flex-row align-items-center justify-content-center">
              <p>{t("noMatchingDataMSG")}</p>
            </div>
          )}

          {!(isDataLoad || loading) &&
            renderItems.length > 0 &&
            renderItems.map((item) => {
              if (impExp === "IMP") {
                return (
                  <ImpItemRender
                    key={`${item.ROWINDEX}_${item.BLNO}`}
                    item={item}
                    impExp={impExp}
                    langMode={langMode}
                    openImpModal={openImpModal}
                    copyInfo={copyInfo}
                  />
                );
              }

              return (
                <ExpItemRender
                  key={`${item.ROWINDEX}_${item.EXP_SDNO}`}
                  item={item}
                  impExp={impExp}
                  langMode={langMode}
                  openExpModal={openExpModal}
                  copyInfo={copyInfo}
                />
              );
            })}

          {(isDataLoad || loading) && (
            <div
              className={`${
                !isTouchBlock ? "h-100" : "min-height-100"
              } w-100 position-relative`}
            >
              <div className="loading">
                <i className="fas fa-spinner" />
                <p>loading</p>
              </div>
            </div>
          )}

          <Pagination
            className={classes.pagination}
            color="info"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              page = value - 1;
              requestLogiworkDataSelect(() => scrollToTop());
            }}
          />
        </div>
      </div>
    </>
  );
}
